import Baffle from "baffle-react";
import React, { Component, Fragment } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import { FiChevronUp, FiMenu, FiX } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Scrollspy from "react-scrollspy";
import Sectors from "../blocks/team/TeamOne";
import Helmet from "../component/common/Helmet";
import FooterTwo from "../component/footer/FooterTwo";
//import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Contact from "../elements/contact/ContactTwo";
import CounterOne from "../elements/counters/CounterOne";
import ServiceList from "../elements/service/ServiceList";
import TabTwo from "../elements/tab/TabTwo";

const sliderDescription = {
  textPosition: "text-center",
  category: "Sviluppiamo prodotti digitali",
  title: "Coding The Future",
  description: "Hai un' idea? Realizziamola insieme!",
  buttonText: "Contattaci",
};

class HomeParticles extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    let title = "L'azienda",
      description =
        "Siamo partiti dal nulla, senza investimenti esterni, senza seguire il percorso canonico delle startup, siamo stati e siamo guidati solo dalla nostra forte volontà di reinvestire ogni anno nella crescita dell'azienda. Questa è una software house dove le aziende trovano opportunità di business, soluzioni complesse e durature. Dopo tanti anni siamo un punto di riferimento per i nostri partner e con loro continuiamo a migliorare i nostri servizi, imparando dal passato e guardando al futuro.";

    return (
      <Fragment>
        <Helmet pageTitle="IM" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo d-lg-block d-none">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/logo-new-white.png"
                    alt="Logo"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/logo-new-white.png"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="logo d-lg-none">
                <a href="/">
                  <img
                    className="logo-1 icon"
                    src="/assets/images/logo/logo-social-im-white.png"
                    alt="Logo"
                  />
                  <img
                    className="logo-2 icon"
                    src="/assets/images/logo/logo-social-im-white.png"
                    alt="Logo"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={[
                    "home",
                    "service",
                    // "portfolio",
                    "about",
                    "sectors",
                    "ourNumbers",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    {/* <a href="#home">Home</a> */}
                    <AnchorLink href="#home">Home</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#service">Servizi</AnchorLink>
                  </li>
                  {/* <li>
                    <AnchorLink href="#portfolio">Portfolio</AnchorLink>
                  </li> */}
                  <li>
                    <AnchorLink href="#about">Chi siamo</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#sectors">Settori</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#ourNumbers">I nostri numeri</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#contact">Contattaci</AnchorLink>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}

        <div
          className="slider-activation slider-creative-agency with-particles"
          id="home"
        >
          {/* <div className="frame-layout__particles">
            <Particles
              params={{
                particles: {
                  number: {
                    value: 50,
                  },
                  size: {
                    value: 4,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: 'repulse',
                    },
                  },
                },
              }}
            />
          </div> */}
          <div className="bg_video">
            <div className="bg_video_overlay"></div>
            <video muted autoPlay playsInline preload="auto" loop>
              <source
                src="/assets/images/bg/video/World-bg.mp4"
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video>
          </div>

          <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner text-center`}>
                    <span>{sliderDescription.category}</span>
                    <h1 className="title theme-gradient">
                      <Baffle
                        speed={200}
                        characters="INNOVATIONMINDS"
                        exclude={[" ", "!"]}
                        obfuscate={false}
                        update={true}
                        revealDuration={1000}
                        revealDelay={1000}
                      >
                        {sliderDescription.title}
                      </Baffle>
                    </h1>

                    <p className="description">
                      {sliderDescription.description}
                    </p>

                    <div className="slide-btn">
                      <AnchorLink
                        className="rn-button-style--2 btn-primary-color"
                        href="#contact"
                      >
                        {sliderDescription.buttonText}
                      </AnchorLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div id="service" className="active-dark">
          <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Cosa Facciamo?</h2>
                    <p>
                      Operiamo nel settore IT e delle consulenze. Crediamo nelle
                      relazioni basate sulla trasparenza, integrità, continuità
                      e fiducia reciproca.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceList
                    item="9"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Sectors Area */}
        {/* <div id="portfolio" className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div> */}
        {/* End Sectors Area */}

        {/* Start About Area */}

        <div id="about" className="active-dark">
          <div className="about-area ptb--120  bg_color--1">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5">
                    <div className="thumbnail">
                      <img
                        className="w-50"
                        src="/assets/images/logo/logo-social-im-white.png"
                        alt="About Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{title}</h2>
                        <p className="description">{description}</p>
                      </div>
                      <div className="mt--30 section-title">
                        <h3 className="title">Come Lavoriamo</h3>
                        <p className="description">
                          I nostri prodotti vengono progettati e sviluppati
                          utilizzando la metodologia AGILE. Il tutto viene
                          organizzato in formula SAL (Stato Avanzamento Lavoro),
                          pianificando incontri periodici con il cliente. Questo
                          ci permette di minimizzare l'errore sul prodotto
                          finale garantendo ai clienti i prodotti che si
                          aspettano. Le fasi principali :
                        </p>
                      </div>
                      <div className="row mt--30">
                        <TabTwo tabStyle="tab-style--1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End About Area */}

        {/* Start sectors Area  */}
        <div className="rn-team-wrapper ptb--120 bg_color--1" id="sectors">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div className="section-title text-left mb--30">
                    <h2>Soluzioni per Imprese e Startup</h2>
                    <p>
                      La tecnologia da sola non basta per fare innovazione nel
                      contesto aziendale, sempre più competitivo. Avviare un
                      progetto di innovazione significa pensare e attuare azioni
                      trasversali che coinvolgano l’intera struttura aziendale e
                      non solo lo sviluppo di nuovi prodotti. Ecco perché il
                      nostro metodo parte da un’analisi del livello di
                      innovazione e sviluppa un progetto di innovazione a 360°
                      basato sul reale potenziale di crescita della tua azienda.
                      <br />
                      <b>
                        Il futuro è oggi. Non c’è più tempo per rimandare il
                        cambiamento.
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <Sectors
                column="col-lg-3 col-md-6 col-sm-6 col-12"
                teamStyle="team-style--bottom"
                item="4"
              />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start CounterUp Area */}
        <div id="ourNumbers" className="active-dark">
          <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight500">Alcuni nostri numeri</h3>
                  </div>
                </div>
              </div>

              <CounterOne />

              <div className="description text-center mt--30">
                <h4 className="fontWeight200">
                  I nostri dati sono reali e possono essere facilmente
                  documentati e provati
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Contact Us */}

        <div className="rn-contact-us ptb--120 bg_color--1" id="contact">
          <Contact />
        </div>

        {/* End Contact Us */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <FiChevronUp />
        </div>

        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default HomeParticles;
