import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

let data = [
    {
        images: '01',
        title: 'RICERCA E SVILUPPO',
        designation: 'Accompagniamo le aziende nella fase di attuazione strategica del piano di innovazione. Scopri il nostro metodo',
        socialNetwork: []
    },
    {
        images: '02',
        title: 'RETAIL',
        designation: 'Progettiamo e implementiamo soluzioni Omnichannel, per creare una eccellente Customer Experience a livello globale',
        socialNetwork: []
    },
    {
        images: '03',
        title: 'ASSET INTANGIBILI',
        designation: 'Software, brevetti, design e know-how offrono grandi opportunità per le aziende: insieme individueremoe valuteremo gli asset intangibili con una stima del profitto potenziale',
        socialNetwork: []
    },
    {
        images: '04',
        title: 'TRASFORMAZIONE DIGITALE',
        designation: 'Offriamo una consulenza integrata volta alla ridefinizione dell’organizzazione e dei processi aziendali nell’ottica di ottimizzazione e digitalizzazione degli stessi',
        socialNetwork: []
    },
    {
        images: '05',
        title: 'Fatima Asrafi',
        designation: 'Front-end Engineer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '06',
        title: 'Fatima Amar',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '07',
        title: 'Fatima Amar',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '09',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },



];

export default data;