import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
  return (
    <p className="success-message">
      Abbiamo ricevuto il tuo messaggio. Ti contatteremo al più presto.
    </p>
  );
};

const CLOUDFLARE_WORKER_URL = 'https://rough-dawn-46f5.inmnd.workers.dev';
const GOOGLE_ReCAPTCHA_KEY = '6Lc3RuYcAAAAAMh7OEhJVO1mh94e3tYPxe1hnjod';

function ContactForm({ props }) {
  const [result, showResult] = useState(false);
  const [contactForm, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const sendRequestToWorker = async (url, contactForm) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(contactForm),
    });
    return response;
  };

  // setTimeout(() => {
  //   showResult(false);
  // }, 5000);

  const sendEmail = (e) => {
    e.preventDefault();

    sendRequestToWorker(CLOUDFLARE_WORKER_URL, contactForm).then(
      () => {},
      (error) => {
        console.log(error);
      }
    );
    showResult(true);
    e.target.reset();
  };

  return !result ? (
    <form id="contactForm" name="contactForm" action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input
          type="text"
          name="fullname"
          placeholder="Nome e cognome"
          onChange={handleChange}
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={handleChange}
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="phone"
          placeholder="Il tuo telefono"
          onChange={handleChange}
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="subject"
          placeholder="Oggetto"
          onChange={handleChange}
          required
        />
      </div>

      <div className="rn-form-group">
        <textarea
          name="message"
          placeholder="Se vuoi, aggiungi qualche dettaglio"
          onChange={handleChange}
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Invia
        </button>
      </div>
      <div className="mt--15">
        <ReCAPTCHA sitekey={GOOGLE_ReCAPTCHA_KEY} />
      </div>
    </form>
  ) : (
    <div className="rn-form-group">
      <Result />{' '}
    </div>
  );
}
export default ContactForm;
