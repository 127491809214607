import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  FiCast,
  FiCodepen,
  FiHardDrive,
  FiLock,
  FiMonitor,
  FiSettings,
  FiSmartphone,
  FiTrendingUp,
  FiUsers,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiSmartphone />,
    title: "Mobile App - iOS & Android",
    description:
      "Analisi, progettazione, sviluppo e manutenzione di app mobile native e non, prodotti consumer e/o custom",
  },
  {
    icon: <FiCodepen />,
    title: "Progettazione e Sviluppo Web",
    description:
      "Web Application, Siti Web, Portali Web, Gestionali, E-Commerce e Blog Aziendali e tanto altro ancora",
  },
  {
    icon: <FiCast />,
    title: "Soluzioni Custom",
    description:
      "Progettazione e sviluppo di software custom per la tua attività.",
  },
  {
    icon: <FiSettings />,
    title: "System Engineering",
    description:
      "Progettazione e sviluppo di infrastrutture elaborative complesse. System integrations & validations, Business intelligence & IoT",
  },
  {
    icon: <FiLock />,
    title: "Blockchain",
    description:
      "Gestione di registri condivisi per facilitare il processo di registrazione delle transazioni e di tracciamento degli asset in una rete di business",
  },
  {
    icon: <FiHardDrive />,
    title: "Sviluppo Embedded",
    description:
      "Progettazione e sviluppo infrastrutture elaborative e software special purpose per utilizzo embedded",
  },
  {
    icon: <FiMonitor />,
    title: "Gestione della tua piattaforma",
    description:
      "Gli anni di esperienza in questo settore ci hanno fatto acquisire un know-how che ci permette di gestire e di tirar fuori il meglio della tua piattaforma: che sia un sito o un e-Commerce. Gestione, manutenzione e aggiornamenti della tua piattaforma ottimizzando la velocità per ricevere più traffico e più conversioni",
  },
  {
    icon: <FiTrendingUp />,
    title: "Digital Marketing",
    description:
      "Analisi, Strategie, Contenuti grafici e/o multimediali, Creazione e gestione di campagne advertising, report mensili con progressi e obiettivi raggiunti. Facebook e instagram ads, google ads, youtube ads, linkedin ads, ebay ads e tanto altro",
  },
  {
    icon: <FiUsers />,
    title: "Consulenza",
    description:
      "Consigli su come utilizzare al meglio le tecnologie dell'informazione e della comunicazione (ICT) al fine di raggiungere determinati obiettivi aziendali. Uniamo gli insights basati sugli analytics, la conoscenza e l’esperienza per collegare le capacità end-to-end necessarie per ottenere cambiamenti e risultati",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContentFirstRow = ServiceList.slice(0, 3);
    const ServiceContentSecondRow = ServiceList.slice(3, 6);
    const ServiceContentThirdRow = ServiceList.slice(6, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContentFirstRow.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          {ServiceContentSecondRow.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          {ServiceContentThirdRow.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className="service service__style--2">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
