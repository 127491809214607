import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "BRIEF",
        tab2 = "PROPOSTA",
        tab3 = "FOCUS",
        tab4 = "CREAZIONE";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <b>PRODUCT INTRO</b><span> - </span>Iniziamo con una chiacchierata sulla tua idea. Ciò ci fornirà i dettagli per sviluppare una proposta.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <b>ANALISI DEL LAVORO</b><span> - </span>Prepariamo un piano per la tua idea basato sui tuoi bisogni. Quando accetterai noi partiremo con lo sviluppo.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <b>SPECIFICHE DI PRODOTTO</b><span> - </span>Grazie a mockups, wireframes, incontri periodici e a prototipi interattivi, ti aiuteremo a focalizzare le specifiche principali del tuo prodotto.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <b>SVILUPPO</b><span> - </span>Realizzeremo il prodotto nei tempi prestabiliti, in stretta collaborazione con il tuo team tecnico.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;